




















































































































































































































































.actions_wrapper {
  // display: flex;
  // gap: 5px;
}
.dialog_footer {
  display: flex;
  justify-content: end;
  align-items: center;

  @media (min-width: 320px) and (max-width: 991px) {
    justify-content: center;
  }
  .save_button {
    // background-color: #23ccef;
    border-color: #00563f;
    background-color: #00563f;
    min-width: 150px;
    color: #fff;
    @media (min-width: 320px) and (max-width: 767px) {
      min-width: 124px;
    }
    &.delete_button {
      background-color: #f56c6c;
      border-color: #f56c6c;
    }
  }
}
